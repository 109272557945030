import React, {useEffect, useState} from "react";

const OtpInput = ({count = 1, value, onChange, setInputFocus: setInputFocusProps, inputFocus: inputFocusProps}) => {
  const [inputFocus, setInputFocus] = useState(null);

  useEffect(() => {
    if (!inputFocusProps) {
      const element = document.getElementById(inputFocus);
      if (element?.value) {
        element.select();
      } else {
        element?.focus();
      }
    } else {
      const element = document.getElementById(inputFocusProps);
      if (element?.value) {
        element.select();
      } else {
        element?.focus();
      }
    }
  }, [inputFocus, inputFocusProps]);

  return (
    <div className="mfa-otp-input-container">
      {Array(count).fill("").map((_, index) => {
        return (
          <input
            key={index}
            id={`otp-input-${index}`}
            inputMode="numeric"
            value={value[index]}
            maxLength={1}
            onChange={e => {
              const pattern = /\D/gim;
              const activeElement = document.activeElement;

              if (!pattern.test(e.target.value)) {
                value[index] = e.target.value;
                onChange([...value]);

                if (activeElement) {
                  const nextElementId = activeElement.id.slice(0, activeElement.id.lastIndexOf("-") + 1) + (index + 1);
                  setInputFocus(nextElementId);
                  if (setInputFocusProps) {
                    setInputFocusProps(nextElementId);
                  }
                }
              }
              if (e.target.value === "") {
                value[index] = e.target.value;
                onChange([...value]);

                if (activeElement) {
                  const nextElementId = activeElement.id.slice(0, activeElement.id.lastIndexOf("-") + 1) + (index - 1);
                  setInputFocus(nextElementId);
                  if (setInputFocusProps) {
                    setInputFocusProps(nextElementId);
                  }
                }
              }
            }}
            onClick={e => {
              setInputFocus(e.target.id);
              if (setInputFocusProps) {
                setInputFocusProps(e.target.id);
              }
            }}
            onKeyDown={e => {
              const activeElement = document.activeElement;
              if (e.shiftKey && e.code === "Tab" && activeElement) {
                e.preventDefault();
                const lastDashIndex = activeElement.id.lastIndexOf("-");
                const previousElementId = activeElement.id.slice(0, lastDashIndex + 1) + (Number(activeElement.id.slice(lastDashIndex + 1)) - 1);
                setInputFocus(previousElementId);
                if (setInputFocusProps) {
                  setInputFocusProps(previousElementId);
                }
              }
            }}
          />
        );
      })}
    </div>
  );
};

export default OtpInput;
